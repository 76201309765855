import { GridJustification } from "@material-ui/core";
import { StackedBarChartView } from "~/components/charts/horizontalStackedBarChart/horizontalStackedBarChart";

export enum AMCAdditionalFieldType {
  CAMPAIGN_GROUP = "CAMPAIGN_GROUP",
  CAMPAIGN = "CAMPAIGN",
  ASIN = "ASIN",
}

export interface AMCAdditionalFieldFilter {
  filterBy: string;
  filterValue: string;
}

export interface AMCAdditionalField {
  label: string;
  type: AMCAdditionalFieldType;
  required: boolean;
  tooltip?: string;
  // For ex: max 5 campaign groups
  maxSelections?: number;
  placeholder?: string;
  filter?: AMCAdditionalFieldFilter;
}

export enum VisualizationType {
  BarChart = "BarChart",
  LineChart = "LineChart",
  PieChart = "PieChart",
  StackedBarChart = "StackedBarChart",
}

interface BucketChartDataBy {
  columnKey: string;
  columnType?: "timestamp";
}

export interface PieChartMetricOption {
  key: string;
  label: string;
  prefix?: string;
  suffix?: string;
  isCurrency?: boolean;
}

export interface PieChartConfig {
  bucketBy: BucketChartDataBy;
  metricOptions: PieChartMetricOption[];
}

export interface BarChartConfig {
  bucketBy: BucketChartDataBy;
}

export enum StackedBarAlignment {
  Horizontal = "Horizontal",
}

export interface StackedBarChartConfig {
  bucketBy: BucketChartDataBy;
  alignment: StackedBarAlignment;
  // columns which add up to the total, for ex: ntb_users
  columnsToInclude: { key: string; label: string }[];
  // name of the column which represents the total for ex: all_users_with_purchase
  totalColumn?: {
    key: string;
    label: string;
    view: StackedBarChartView;
  };
  // locize key to be used for remaining, for ex: non_ntb_users
  // this label will be given to (total - sum(columns)), for ex: non_ntb_users = all_users_with_purchase - ntb_users
  remaining?: { key: string; label: string };
  formatAsPercentage: boolean;
  view: StackedBarChartView;
}

export type VisualRepresentation =
  | {
      type: VisualizationType.PieChart;
      config: PieChartConfig;
    }
  | {
      type: VisualizationType.BarChart | VisualizationType.LineChart;
      config: BarChartConfig;
    }
  | {
      type: VisualizationType.StackedBarChart;
      config: StackedBarChartConfig;
    };

export enum CellType {
  TextCell = "TextCell",
  ValueAndGrowthCell = "ValueAndGrowthCell",
  PathCell = "PathCell",
}

export enum CellAccessor {
  PathList = "pathList",
  Value = "value",
  Currency = "currency",
}

export enum FilterTypes {
  MultiSelect = "multiSelect",
}

export enum FilterBy {
  CampaignGroups = "campaignGroups",
}

interface TabularRepresentationColumn {
  id: string;
  header: string;
  accessor?: CellAccessor;
  cellType?: CellType;
  isVisible: boolean;
  align: "left" | "center" | "right";
  cellJustify?: GridJustification;
  customWidth?: string;
  divideRight?: boolean;
  isPPT?: boolean; // for percentage type fields
  toFixed?: number; // number of decimal places
  customSuffix?: string; // ex: %
  reverseFormatting?: boolean; // for fields like acos
  isSortable?: boolean;
}

export interface TabularRepresentation {
  primaryKey: string[];
  columns: TabularRepresentationColumn[];
  filter?: {
    type: FilterTypes;
    label: string;
    filterColumn: string;
    filterBy: FilterBy;
  };
}

export interface AMCReportColumns {
  id: string;
  type: "string" | "number" | "boolean";
  aggregate: "sum" | "average" | "max" | "min" | "any" | "none";
}

export interface AMCReportType {
  _id: string;
  name: string;
  additionalFields?: Record<string, AMCAdditionalField>;
  tabular?: TabularRepresentation;
  visualizations?: VisualRepresentation[];
  reportColumns: AMCReportColumns[];
}
