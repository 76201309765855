import {
  AMCAdditionalFieldType,
  AMCReportType,
} from "~/typedef/amc/reportType";

import { AMCAdditionalFieldValues } from "~/typedef/amc/scheduledReport";

export const getDefaultParameterValues = (
  reportType: AMCReportType
): AMCAdditionalFieldValues => {
  return reportType.additionalFields
    ? Object.entries(
        reportType.additionalFields
      ).reduce<AMCAdditionalFieldValues>((acc, [name, field]) => {
        switch (field.type) {
          case AMCAdditionalFieldType.CAMPAIGN:
            if (field.required) {
              acc[name] = [];
            }
            break;
        }
        return acc;
      }, {}) ?? {}
    : {};
};

export const isValidParameterValues = (
  reportType: AMCReportType,
  parameterValues: AMCAdditionalFieldValues
) => {
  if (reportType.additionalFields) {
    return Object.entries(reportType.additionalFields).every(
      ([name, field]) => {
        if (field.required) {
          const fieldValue = parameterValues[name];
          return (
            fieldValue &&
            (typeof fieldValue !== "string" || fieldValue.trim() !== "")
          );
        }
        return true;
      }
    );
  }
  return true;
};
