import {
  AMCAdditionalFieldFilter,
  AMCReportColumns,
  AMCReportType,
  VisualizationType,
} from "~/typedef/amc/reportType";
import {
  AMCAdditionalFieldValues,
  AMCScheduledReport,
} from "~/typedef/amc/scheduledReport";
import {
  AMCCSVRows,
  AMCGenericReportTabularResponse,
} from "~/typedef/amc/amcReport";
import { Campaign, PrunedCampaignGroup } from "~/typedef/amc/campaignGroups";
import { TAG_TYPES, api } from "@store/apiSlice";

import { AMCScheduledReportRun } from "~/typedef/amc/scheduledReportRun";
import { INTERVAL } from "~/store/utils/dateTimeUtils";
import { PaginationArgs } from "~/typedef/pagination";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";

interface FetchReportTypesResponse {
  reportTypes: AMCReportType[];
}

interface FetchScheduledReportsArgs {
  mid: string;
  queryType: string;
}

interface FetchScheduledReportsResponse {
  scheduledReports: AMCScheduledReport[];
}

export interface CreateScheduledReportArgs {
  mid: string;
  queryType: string;
  reportName: string;
  reportConfig: {
    frequency: string;
    currentPeriod: string;
    comparisonPeriod: string;
    timezone: string;
    currency: string;
    reportingDelay?: number;
    //unix timestamps
    fromDate?: number;
    toDate?: number;
  };
  description: string;
  reportRecipients: string[];
  parameterValues?: AMCAdditionalFieldValues;
}

interface CreateScheduledReportResponse {}

interface FetchReportRunsArgs {
  mid: string;
  scheduledReportId: string;
}

interface FetchReportRunsResponse {
  reportRuns: AMCScheduledReportRun[];
}

interface FetchReportRunArgs {
  paginationParams: PaginationArgs;
  searchParams: {
    filterValue?: string[];
    filterKey?: string;
  };
  currentPeriodPrimaryKeyValues?: string[];
  reportRunId: string;
}

interface FetchReportRunChartDataArgs {
  reportRunId: string;
  type: VisualizationType;
  interval: INTERVAL;
}

interface FetchReportRunChartDataResponse {
  reportColumns: AMCReportColumns[];
  chartData: AMCCSVRows[];
}

interface FetchReportRunDownloadUrlArgs {
  reportRunId: string;
}

interface FetchReportRunDownloadUrlResponse {
  downloadUrl: string;
}

interface FetchCampaignGroupsArgs {
  mid: string;
}

interface FetchCampaignGroupsResponse {
  campaignGroups: PrunedCampaignGroup[];
}

interface FetchCampaignsArgs extends Partial<AMCAdditionalFieldFilter> {
  mid: string;
  filterType?: "equals" | "regex";
}

interface FetchCampaignsResponse {
  campaigns: Campaign[];
}

interface CreateCampaignGroupResponse {
  campaignGroup: PrunedCampaignGroup;
}

export interface CreateCampaignGroupRequest {
  mid: string;
  name: string;
  campaigns: {
    id: string;
    name: string;
  }[];
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    reportTypes: build.query<FetchReportTypesResponse, {}>({
      query: () => {
        return {
          url: `${baseUrl}/api/amazon-marketing-cloud-service/api/reportType`,
          method: "GET",
        };
      },
      onQueryStarted: globalQueryErrorHandler("AMCReportTypes"),
    }),
    scheduledReports: build.query<
      FetchScheduledReportsResponse,
      FetchScheduledReportsArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/amazon-marketing-cloud-service/api/scheduledReport`,
          method: "GET",
          params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("AMCScheduledReports"),
    }),
    createScheduledReport: build.mutation<
      CreateScheduledReportArgs,
      CreateScheduledReportResponse
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/amazon-marketing-cloud-service/api/scheduledReport/`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("AMCScheduledReports"),
    }),
    reportRuns: build.query<FetchReportRunsResponse, FetchReportRunsArgs>({
      query: (params) => {
        return {
          url: `${baseUrl}/api/amazon-marketing-cloud-service/api/reportRun`,
          method: "GET",
          params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("AMCReportRuns"),
    }),
    reportRunData: build.query<
      AMCGenericReportTabularResponse,
      FetchReportRunArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/amazon-marketing-cloud-service/api/publicReportRun/${params.reportRunId}/tabular`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("AMCReportRunData"),
    }),
    reportRunChartData: build.query<
      FetchReportRunChartDataResponse,
      FetchReportRunChartDataArgs
    >({
      query: ({ reportRunId, ...otherParams }) => {
        return {
          url: `${baseUrl}/api/amazon-marketing-cloud-service/api/publicReportRun/${reportRunId}/chartData`,
          method: "GET",
          params: otherParams,
        };
      },
      onQueryStarted: globalQueryErrorHandler("AMCReportRunChart"),
    }),
    reportRunDownloadUrl: build.query<
      FetchReportRunDownloadUrlResponse,
      FetchReportRunDownloadUrlArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/amazon-marketing-cloud-service/api/reportRun/${params.reportRunId}/downloadUrl`,
          method: "GET",
          params,
        };
      },
    }),
    getCampaigns: build.query<FetchCampaignsResponse, FetchCampaignsArgs>({
      query: (params) => {
        return {
          url: `${baseUrl}/api/amazon-marketing-cloud-service/api/campaigns`,
          method: "GET",
          params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("AMCCampaigns"),
    }),
    getCampaignGroups: build.query<
      FetchCampaignGroupsResponse,
      FetchCampaignGroupsArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/amazon-marketing-cloud-service/api/campaignGroups`,
          method: "GET",
          params,
        };
      },
      providesTags: [TAG_TYPES.CampaignGroups],
      onQueryStarted: globalQueryErrorHandler("AMCCampaignGroups"),
    }),
    createCampaignGroup: build.mutation<
      CreateCampaignGroupResponse,
      CreateCampaignGroupRequest
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/amazon-marketing-cloud-service/api/campaignGroups`,
          method: "POST",
          data: params,
        };
      },
      invalidatesTags: [TAG_TYPES.CampaignGroups],
      onQueryStarted: globalQueryErrorHandler("AMCCreateCampaignGroup"),
    }),
  }),
});

export const {
  useReportTypesQuery,
  useScheduledReportsQuery,
  useCreateScheduledReportMutation,
  useReportRunsQuery,
  useReportRunDataQuery,
  useReportRunChartDataQuery,
  useReportRunDownloadUrlQuery,
  useGetCampaignGroupsQuery,
  useGetCampaignsQuery,
  useCreateCampaignGroupMutation,
} = extendedApiSlice;
